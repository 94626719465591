export default {
    "en": {
        "": "",
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more errors)",
        "* Required fields": "* Required fields",
        "+": "+",
        "+1": "+1",
        "-": "-",
        "6-Digit code": "6-Digit code",
        "404 - Page not found": "404 - Page not found",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{Attribute} is invalid": "{Attribute} is invalid",
        "{count} characters": "{count} characters",
        "{model} {action}": "{model} {action}",
        "{variant} image": "{variant} image",
        "About us": "About us",
        "Account": "Account",
        "Activated": "activated",
        "activated": "activated",
        "Active": "Active",
        "Add comment": "Add comment",
        "Added product to cart": "Added product to cart",
        "Add leave registration": "Add leave registration",
        "Add menu item": "Add menu item",
        "Add message": "Add message",
        "add one": "add one",
        "Add page block": "Add page block",
        "Add Time Off": "Add Time Off",
        "Add to cart": "Add to cart",
        "Add to favorites": "Add to favorites",
        "Add to shopping cart": "Add to shopping cart",
        "Admin": "Admin",
        "Administrator": "Administrator",
        "All": "All",
        "All {models}": "All {models}",
        "All blogs": "All blogs",
        "Allow cookies": "Allow cookies",
        "All Products": "All Products",
        "All products": "All products",
        "All questions": "All questions",
        "All rights reserved.": "All rights reserved.",
        "Alt text": "Alt text",
        "Amount": "Amount",
        "Amount of products": "Amount of products",
        "Answer": "Answer",
        "A piece": "A piece",
        "Apply discount code": "Apply discount code",
        "Approved": "Approved",
        "App Settings": "App Settings",
        "Author": "Author",
        "Back": "Back",
        "Back and mark as done": "Back and mark as done",
        "Back and release": "Back and release",
        "Back to home": "Back to home",
        "Back to the shopping cart": "Back to the shopping cart",
        "Backup codes": "Backup codes",
        "Balance": "Balance",
        "Banner": "Banner",
        "Billing address": "Billing address",
        "Blog": "Blog",
        "Blogs": "Blogs",
        "Blog Settings": "Blog Settings",
        "Blue Button": "Blue Button",
        "BTW number": "BTW number",
        "Bullet point": "Bullet point",
        "Bullet points": "Bullet points",
        "Button link": "Button link",
        "Buttons": "Buttons",
        "Button text": "Button text",
        "By subscribing you agree to receive our newsletter.": "By subscribing you agree to receive our newsletter.",
        "Categories": "Categories",
        "Category": "Category",
        "Category Settings": "Category Settings",
        "Category slider": "Category slider",
        "Change": "Change",
        "Change password": "Change password",
        "Change time off {name}": "Change time off {name}",
        "Checkout": "Checkout",
        "Checkout page": "Checkout page",
        "Choose link type": "Choose link type",
        "City": "City",
        "Client": "Client",
        "Clients": "Clients",
        "Close conversation": "Close conversation",
        "closed": "closed",
        "CoC": "CoC",
        "Coc:": "Coc:",
        "Coc number": "Coc number",
        "Code": "Code",
        "code": "code",
        "Comment": "Comment",
        "Commerce settings": "Commerce settings",
        "Company details": "Company details",
        "Company name": "Company name",
        "Complete": "Complete",
        "Concept in only visible to logged in users, draft is not visible to anyone": "Concept in only visible to logged in users, draft is not visible to anyone",
        "Conditional Page link": "Conditional Page link",
        "Confirm Password": "Confirm Password",
        "Confirm password": "Confirm password",
        "Consultant": "Consultant",
        "Contact": "Contact",
        "Contact information": "Contact information",
        "Content": "Content",
        "Content (Collapsible)": "Content (Collapsible)",
        "Contract": "Contract",
        "Conversation": "Conversation",
        "Conversations": "Conversations",
        "Coupon": "Coupon",
        "Coupon applied": "Coupon applied",
        "Coupon can no longer be used": "Coupon can no longer be used",
        "Coupon not found": "Coupon not found",
        "Coupons": "Coupons",
        "Create {model}": "Create {model}",
        "Create an account": "Create an account",
        "Create contract": "Create contract",
        "created": "created",
        "Created at": "Created at",
        "Create follow-up": "Create follow-up",
        "Create leave registration": "Create leave registration",
        "Create order": "Create order",
        "Currently no stock": "Currently no stock",
        "Current Password": "Current Password",
        "Current password": "Current password",
        "Customers will be shown the cheapest applicable shipping rate from each group. Currently existing groups: {groups}": "Customers will be shown the cheapest applicable shipping rate from each group. Currently existing groups: {groups}",
        "Dangerously high": "Dangerously high",
        "Dangerously high explanation": "Dangerously high explanation",
        "Dangerously Low": "Dangerously Low",
        "Dangerously low explanation": "Dangerously low explanation",
        "Dashboard": "Dashboard",
        "Date": "Date",
        "Date of birth": "Date of birth",
        "Deactivate": "Deactivate",
        "Deactivated": "deactivated",
        "deactivated": "deactivated",
        "Decline cookies": "Decline cookies",
        "Default consultant": "Default consultant",
        "De laatste trends": "De laatste trends",
        "deleted": "deleted",
        "Delivery options": "Delivery options",
        "Delivery or pick up?": "Delivery or pick up?",
        "Description": "Description",
        "description": "description",
        "Desktop": "Desktop",
        "Details": "Details",
        "Disable swiper": "Disable swiper",
        "Disabling a coupon will prevent it from being used": "Disabling a coupon will prevent it from being used",
        "Disclaimer": "Disclaimer",
        "Discount": "Discount",
        "Discount active": "Discount active",
        "Discount code": "Discount code",
        "done": "done",
        "Don’t have access to your authenticator device ?": "Don’t have access to your authenticator device ?",
        "Down": "Down",
        "Download app": "Download app",
        "Download backup codes and continue.": "Download backup codes and continue.",
        "Download invoice": "Download invoice",
        "Due at": "Due at",
        "E-mail": "E-mail",
        "e-mail": "E-mail",
        "Edit": "Edit",
        "Edit {model}": "Edit {model}",
        "Edit account": "Edit account",
        "Edit leave registration": "Edit leave registration",
        "Email": "Email",
        "email": "email",
        "Email address": "Email address",
        "employee": "employee",
        "Employee": "Employee",
        "Employees": "Employees",
        "End": "End",
        "Enter your e-mail and we send you a reset link": "Enter your e-mail and we send you a reset link",
        "Excerpt": "Excerpt",
        "excerpt": "excerpt",
        "External Link": "External Link",
        "External Test": "External Test",
        "External Tester": "External Tester",
        "Facebook": "Facebook",
        "Facebook link": "Facebook link",
        "Faq": "Faq",
        "Faqs": "Faqs",
        "Fast delivery": "Fast delivery",
        "Favorites": "Favorites",
        "favorites": "favorites",
        "Featured Image": "Featured Image",
        "Female": "Female",
        "Fill in the 6-digit code": "Fill in the 6-digit code",
        "Fill in this code in the app.": "Fill in this code in the app.",
        "Filter": "Filter",
        "Filters": "Filters",
        "First name": "First name",
        "First Name": "First Name",
        "First things first": "First things first",
        "Fixed": "Fixed",
        "Focal point": "Focal point",
        "Follow up to": "Follow up to",
        "Follow up tot": "Follow up tot",
        "Follow us": "Follow us",
        "Follow us on": "Follow us on",
        "Forbidden": "Forbidden",
        "Forgot password": "Forgot password",
        "Forgot password?": "Forgot password?",
        "Forgotten password?": "Forgotten password?",
        "Forgot your password?": "Forgot your password?",
        "Forgot your password? Send a password reset link": "Forgot your password? Send a password reset link",
        "Form submission": "Form submission",
        "Form submissions": "Form submissions",
        "Free": "Free",
        "FREE SHIPPING WITH ORDERS OVER €75": "FREE SHIPPING WITH ORDERS OVER €75",
        "From the authenticator app": "From the authenticator app",
        "Gain muscle product": "Gain muscle product",
        "Gallery": "Gallery",
        "General slider": "General slider",
        "Generate variants": "Generate variants",
        "Google Authenticator": "Google Authenticator",
        "Google Maps URL": "Google Maps URL",
        "Google maps URL": "Google maps URL",
        "Go to page {page}": "Go to page {page}",
        "GRATIS VERZENDING BIJ BESTEDING VAN € 75,-": "GRATIS VERZENDING BIJ BESTEDING VAN € 75,-",
        "Group": "Group",
        "Has image": "Has image",
        "Heading": "Heading",
        "Hello": "Hello",
        "Hello!": "Hello!",
        "Hide on page": "Hide on page",
        "High": "High",
        "Highest quality": "Highest quality",
        "High explanation": "High explanation",
        "Hits": "Hits",
        "Home": "Home",
        "Hoogste kwaliteit": "Hoogste kwaliteit",
        "Hours": "Hours",
        "Hours per week": "Hours per week",
        "I am new here": "I am new here",
        "Icon": "Icon",
        "If checked, the image will be full width.": "If checked, the image will be full width.",
        "If checked, the image will have a gradient. Only has effect if \"Image full width\" is checked.": "If checked, the image will have a gradient. Only has effect if \"Image full width\" is checked.",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "If enabled, and no image is uploaded, the banner from the homepage will be shown.",
        "If left empty, the page title will be used.": "If left empty, the page title will be used.",
        "If left empty, the title will be used.": "If left empty, the title will be used.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:",
        "If you did not create an account, no further action is required.": "If you did not create an account, no further action is required.",
        "If you did not request a password reset, no further action is required.": "If you did not request a password reset, no further action is required.",
        "I have saved the backup codes": "I have saved the backup codes",
        "Image": "Image",
        "Image CTA": "Image CTA",
        "Image full width": "Image full width",
        "Images": "Images",
        "Include inactive": "Include inactive",
        "Include Inactive": "Include Inactive",
        "Including VAT": "Including VAT",
        "Index Page": "Index Page",
        "Inloggen": "Inloggen",
        "In shoppingcart": "In shoppingcart",
        "Inspection": "Inspection",
        "Instagram": "Instagram",
        "Instagram link": "Instagram link",
        "Instruction title": "Instruction title",
        "Internal comment": "Internal comment",
        "Internal comments": "Internal comments",
        "Introduction": "Introduction",
        "Invalid phone number": "Invalid phone number",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "It is recommended to keep the {attribute} between {min} and {max} characters long",
        "KVK number": "KVK number",
        "Label": "Label",
        "Label Button": "Label Button",
        "Language": "Language",
        "Last message on": "Last message on",
        "Last name": "Last name",
        "Last Name": "Last Name",
        "Last refresh was {quantity} {unit} ago": "Last refresh was {quantity} {unit} ago",
        "Leave empty for no end date": "Leave empty for no end date",
        "Leave empty to generate a random code. Not editable after creation.": "Leave empty to generate a random code. Not editable after creation.",
        "Leave empty to use 'Related products'": "Leave empty to use 'Related products'",
        "Leave empty to use parent details": "Leave empty to use parent details",
        "Leave registratie": "Leave registratie",
        "leave registration": "leave registration",
        "Leave registrations": "Leave registrations",
        "Link Button": "Link Button",
        "Linkedin link": "Linkedin link",
        "Login": "Login",
        "Log in": "Log in",
        "Login / Register": "Login / Register",
        "Login to your account in our secure environment": "Login to your account in our secure environment",
        "Login with a recovery code.": "Login with a recovery code.",
        "Logout": "Logout",
        "Lose weight product": "Lose weight product",
        "Low": "Low",
        "Low explanation": "Low explanation",
        "Made by:": "Made by:",
        "Maintain muscle product": "Maintain muscle product",
        "Main variant": "Main variant",
        "Make main": "Make main",
        "Make your decision:": "Make your decision:",
        "Male": "Male",
        "Mark as done": "Mark as done",
        "Max users": "Max users",
        "Medical": "Medical",
        "Meer informatie": "Meer informatie",
        "Menu": "Menu",
        "Menu items": "Menu items",
        "Menus": "Menus",
        "Message": "Message",
        "Messages": "Messages",
        "Metric Name": "Metric Name",
        "Microsoft Authenticator": "Microsoft Authenticator",
        "Minimal order amount": "Minimal order amount",
        "Mobile": "Mobile",
        "More": "More",
        "More information": "More information",
        "More settings can be set in the Page Block Settings page": "More settings can be set in the Page Block Settings page",
        "Most sold": "Most sold",
        "Mute client": "Mute client",
        "Muted": "Muted",
        "muted": "muted",
        "My leave registrations": "My leave registrations",
        "My Time registrations": "My Time registrations",
        "Name": "Name",
        "name": "name",
        "Name client": "Name client",
        "Newest": "Newest",
        "New Password": "New Password",
        "New password": "New password",
        "New URL": "New URL",
        "Next": "Next",
        "No": "No",
        "No favorites yet": "No favorites yet",
        "No items": "No items",
        "None": "None",
        "No options have been created yet": "No options have been created yet",
        "No orders yet": "No orders yet",
        "No products found. Try again": "No products found. Try again",
        "No results found": "No results found",
        "No settings available": "No settings available",
        "Not available": "Not available",
        "Not available in this combination": "Not available in this combination",
        "Not editable after creation.": "Not editable after creation.",
        "Not Found": "Not Found",
        "Notifications": "Notifications",
        "Not implemented yet": "Not implemented yet",
        "Number": "Number",
        "Number of hours": "Number of hours",
        "of": "of",
        "Old URL": "Old URL",
        "Only send a warning once every x days.": "Only send a warning once every x days.",
        "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.": "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.",
        "Openings hours": "Openings hours",
        "Open in new tab": "Open in new tab",
        "Open the authenticator app, add a new entry and scan the QR code": "Open the authenticator app, add a new entry and scan the QR code",
        "Options": "Options",
        "Orange Button": "Orange Button",
        "Order": "Order",
        "Order now": "Order now",
        "Order number": "Order number",
        "Orders": "Orders",
        "Order was created": "Order was created",
        "Overtime Balance": "Overtime Balance",
        "Overtime Balance: {balance}": "Overtime Balance: {balance}",
        "Overtime warning interval": "Overtime warning interval",
        "Overtime warning threshold": "Overtime warning threshold",
        "Overview": "Overview",
        "Own leave registrations": "Own leave registrations",
        "Page": "Page",
        "Page blocks": "Page blocks",
        "Page block settings": "Page block settings",
        "Page button": "Page button",
        "Page Content": "Page Content",
        "Page Expired": "Page Expired",
        "Page link": "Page link",
        "Pages": "Pages",
        "Page Title": "Page Title",
        "Pagination Navigation": "Pagination Navigation",
        "Paragraph": "Paragraph",
        "Parent category": "Parent category",
        "Password": "Password",
        "Password reset": "Password reset",
        "Password reset link has been sent": "Password reset link has been sent",
        "Paste or type the code we sent a code to your e-mail.": "Paste or type the code we sent a code to your e-mail.",
        "Paste or type the code we sent a code to your phone number.": "Paste or type the code we sent a code to your phone number.",
        "Payment": "Payment",
        "Payment Required": "Payment Required",
        "Payments": "Payments",
        "Percentage": "Percentage",
        "Perfecte pasvorm": "Perfecte pasvorm",
        "Perfect fit": "Perfect fit",
        "Permanent": "Permanent",
        "Phone": "Phone",
        "Phone number": "Phone number",
        "phone number": "phone number",
        "Phone Number": "Phone Number",
        "phone_number": "phone_number",
        "Pick-up": "Pick-up",
        "Place order": "Place order",
        "Please click the button below to verify your email address.": "Please click the button below to verify your email address.",
        "Please confirm access to your account by entering one of your emergency recovery codes.": "Please confirm access to your account by entering one of your emergency recovery codes.",
        "Please confirm access to your account by entering the authentication code provided by your authenticator application.": "Please confirm access to your account by entering the authentication code provided by your authenticator application.",
        "Please use the link in your email to view your order.": "Please use the link in your email to view your order.",
        "Postal code": "Postal code",
        "Postcode": "Postcode",
        "Premium quality": "Premium quality",
        "Previous": "Previous",
        "Price": "Price",
        "Price (high to low)": "Price (high to low)",
        "Price (low to high)": "Price (low to high)",
        "Price: High - Low": "Price: High - Low",
        "Price: Low - High": "Price: Low - High",
        "Print": "Print",
        "Priority": "Priority",
        "Privacy declaration": "Privacy declaration",
        "Privacy page": "Privacy page",
        "Product": "Product",
        "Product details": "Product details",
        "Product information": "Product information",
        "Product option": "Product option",
        "Product options": "Product options",
        "Products": "Products",
        "products": "Products",
        "Product Settings": "Product Settings",
        "Product slider": "Product slider",
        "Publish date": "Publish date",
        "Published At": "Published At",
        "Published from": "Published from",
        "Published until": "Published until",
        "Quantity": "Quantity",
        "Question": "Question",
        "Randomize image": "Randomize image",
        "Reactivate": "Reactivate",
        "Read more": "Read more",
        "Recommended products": "Recommended products",
        "Redirect": "Redirect",
        "Redirects": "Redirects",
        "Regards": "Regards",
        "Register": "Register",
        "Register to your account in our secure environment": "Register to your account in our secure environment",
        "Rejected": "Rejected",
        "Related products": "Related products",
        "Remember me": "Remember me",
        "Remove": "Remove",
        "Remove discount code": "Remove discount code",
        "Remove from favorites": "Remove from favorites",
        "remove one": "remove one",
        "remove product": "remove product",
        "Remove product from cart": "Remove product from cart",
        "Reopened subtask": "Reopened subtask",
        "Resend": "Resend",
        "Resend {attribute}": "Resend {attribute}",
        "Resend in": "Resend in",
        "Reserved By": "Reserved By",
        "Reset Password": "Reset Password",
        "Reset password": "Reset password",
        "Reset Password Notification": "Reset Password Notification",
        "Results": "results",
        "results": "results",
        "Results are shared": "Results are shared",
        "Reviews": "Reviews",
        "RGB color": "RGB color",
        "Rights": "Rights",
        "Role": "Role",
        "role": "role",
        "Roles": "Roles",
        "Saldo: {saldo}": "Saldo: {saldo}",
        "Sales": "Sales",
        "Sampled at": "Sampled at",
        "Save": "Save",
        "Save in favorites": "Save in favorites",
        "Save these codes on a secure place if you can’t get access with your Authenticator app.": "Save these codes on a secure place if you can’t get access with your Authenticator app.",
        "Scanning not possible?": "Scanning not possible?",
        "Scanning not possible? Fill in this code in the app.": "Scanning not possible? Fill in this code in the app.",
        "Scan the QR code": "Scan the QR code",
        "Search": "Search",
        "Search {model}": "Search {model}",
        "sec": "sec",
        "Secure payment": "Secure payment",
        "Select": "Select",
        "Select a {model}": "Select a {model}",
        "Select a page": "Select a page",
        "Select a template to add the corresponding blocks.": "Select a template to add the corresponding blocks.",
        "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created": "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created",
        "Select a test": "Select a test",
        "Select a type": "Select a type",
        "Select the number of hours": "Select the number of hours",
        "Select the type": "Select the type",
        "Send": "Send",
        "Send and return": "Send and return",
        "Send a password reset link": "Send a password reset link",
        "Send a warning when the overtime is above this threshold.": "Send a warning when the overtime is above this threshold.",
        "Send e-mail": "Send e-mail",
        "Send email": "Send email",
        "Send link": "Send link",
        "Send verification {attribute}": "Send verification {attribute}",
        "Seo Settings": "Seo Settings",
        "SEO title": "SEO title",
        "Server Error": "Server Error",
        "Service": "Service",
        "Service Unavailable": "Service Unavailable",
        "Set  password": "Set  password",
        "Sets": "Sets",
        "Settings": "Settings",
        "Set Two Factor Authentication": "Set Two Factor Authentication",
        "Set your secure password below": "Set your secure password below",
        "Sex": "Sex",
        "shared": "shared",
        "Share results": "Share results",
        "Shipping address": "Shipping address",
        "Shipping address same as billing address": "Shipping address same as billing address",
        "Shipping and billing address": "Shipping and billing address",
        "Shipping and return information": "Shipping and return information",
        "Shipping cost": "Shipping cost",
        "Shipping method": "Shipping method",
        "Shipping Rate": "Shipping Rate",
        "shipping rate": "shipping rate",
        "Shipping Rates": "Shipping Rates",
        "Shop": "Shop",
        "Shopping cart": "Shopping cart",
        "Shopping cart page": "Shopping cart page",
        "Showing": "Showing",
        "Show less": "Show less",
        "Show more": "Show more",
        "Show products": "Show products",
        "Show rectangle": "Show rectangle",
        "sidebar": "sidebar",
        "Sign out": "Sign out",
        "Sign up for our newsletter": "Sign up for our newsletter",
        "Since it’s your first time logging in here, we’ve sent a code to your phone to verify your user profile.": "Since it’s your first time logging in here, we’ve sent a code to your phone to verify your user profile.",
        "Size chart image": "Size chart image",
        "Slides": "Slides",
        "Slug": "Slug",
        "Sort": "Sort",
        "Standard": "Standard",
        "Start": "Start",
        "Started at": "Started at",
        "Status": "Status",
        "Statuses": "Statuses",
        "Stock": "Stock",
        "Street name": "Street name",
        "Street number": "Street number",
        "Street number addition": "Street number addition",
        "Subject": "Subject",
        "Sub menu": "Sub menu",
        "Submitted": "Submitted",
        "Subscribe": "Subscribe",
        "SubTasks": "SubTasks",
        "Subtitle": "Subtitle",
        "subtitle": "subtitle",
        "Subtotal": "Subtotal",
        "Tags": "Tags",
        "Task": "Task",
        "Tasks": "Tasks",
        "Tel": "Tel",
        "Terms and Conditions": "Terms and Conditions",
        "Terms page": "Terms page",
        "Test": "Test",
        "test": "test",
        "Tests": "Tests",
        "Test set": "Test set",
        "test set": "test set",
        "Test sets": "Test sets",
        "Text": "Text",
        "Thank you for shopping with us!": "Thank you for shopping with us!",
        "Thank you for your message.": "Thank you for your message.",
        "Thank you page settings": "Thank you page settings",
        "The {attribute} has already been taken.": "The {attribute} has already been taken.",
        "The {attribute} must be verified.": "The {attribute} must be verified.",
        "The {attribute} must contain at least one letter.": "The {attribute} must contain at least one letter.",
        "The {attribute} must contain at least one number.": "The {attribute} must contain at least one number.",
        "The {attribute} must contain at least one symbol.": "The {attribute} must contain at least one symbol.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "The {attribute} must contain at least one uppercase and one lowercase letter.",
        "The coupon has already been used.": "The coupon has already been used.",
        "The coupon has expired.": "The coupon has expired.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.",
        "The given data was invalid.": "The given data was invalid.",
        "The latest trends": "The latest trends",
        "The password field is required when creating an account.": "The password field is required when creating an account.",
        "The provided {attribute} is invalid.": "The provided {attribute} is invalid.",
        "The provided code was invalid.": "The provided code was invalid.",
        "The provided password does not match your current password.": "The provided password does not match your current password.",
        "The provided two factor authentication code was invalid.": "The provided two factor authentication code was invalid.",
        "The tests cannot be changed once the test set is created.": "The tests cannot be changed once the test set is created.",
        "This {attribute} has already been used": "This {attribute} has already been used",
        "This area is restricted to medical medic members only.": "This area is restricted to medical medic members only.",
        "This area is restricted to staff members only.": "This area is restricted to staff members only.",
        "This is your first login": "This is your first login",
        "This password reset link will expire in {count} minutes.": "This password reset link will expire in {count} minutes.",
        "This shipping rate is not applicable to your cart.": "This shipping rate is not applicable to your cart.",
        "This task is reserved by {userName}": "This task is reserved by {userName}",
        "This task is reserved by {userName}.": "This task is reserved by {userName}.",
        "Tiktoc link": "Tiktoc link",
        "TikTok": "TikTok",
        "TimeOff": "TimeOff",
        "TimeOff warning threshold": "TimeOff warning threshold",
        "Time off warning threshold": "Time off warning threshold",
        "time registration": "time registration",
        "Time registrations": "Time registrations",
        "Times used": "Times used",
        "Title": "Title",
        "title": "title",
        "to": "to",
        "TODO": "TODO",
        "Toggle navigation": "Toggle navigation",
        "token": "token",
        "Too Many Requests": "Too Many Requests",
        "Totaal products ({number})": "Totaal products ({number})",
        "Total": "Total",
        "Total saving": "Total saving",
        "To use Top Chefs Two Factor Authentication is required as extra security, you can set this now": "To use Top Chefs Two Factor Authentication is required as extra security, you can set this now",
        "Trans": "Trans",
        "Two-factor Confirmation": "Two-factor Confirmation",
        "Two Factor Authentication": "Two Factor Authentication",
        "Two Factor Authentication - Backup codes": "Two Factor Authentication - Backup codes",
        "Two Factor Authentication is required as extra security, you can set this now": "Two Factor Authentication is required as extra security, you can set this now",
        "Two factor challenge": "Two factor challenge",
        "Type": "Type",
        "Unauthorized": "Unauthorized",
        "Unisex": "Unisex",
        "Unmute client": "Unmute client",
        "unmuted": "unmuted",
        "Up": "Up",
        "Update account": "Update account",
        "Update contract": "Update contract",
        "updated": "updated",
        "Update your info here.": "Update your info here.",
        "Url": "Url",
        "url": "url",
        "Use an authentication code": "Use an authentication code",
        "User": "User",
        "user": "user",
        "Users": "Users",
        "Use `-1` for unlimited usage": "Use `-1` for unlimited usage",
        "USPs": "USPs",
        "Uw winkelmand is leeg": "Uw winkelmand is leeg",
        "Vacation Balance": "Vacation Balance",
        "Vacation Balance: {balance}": "Vacation Balance: {balance}",
        "Vacation Days Per Year": "Vacation Days Per Year",
        "Vacation hours": "Vacation hours",
        "Vacation Hours Per Year": "Vacation Hours Per Year",
        "Value": "Value",
        "Values": "Values",
        "Variant": "Variant",
        "Variants": "Variants",
        "VAT": "VAT",
        "VAT:": "VAT:",
        "VAT number": "VAT number",
        "Verify": "Verify",
        "Verify {attribute}": "Verify {attribute}",
        "Verify Email Address": "Verify Email Address",
        "Verify your phone number": "Verify your phone number",
        "View order": "View order",
        "Waiting for payment conformation": "Waiting for payment conformation",
        "Warning: limited stock!": "Warning: limited stock!",
        "Washing instructions": "Washing instructions",
        "Was unable to find an address with this postcode and street number": "Was unable to find an address with this postcode and street number",
        "Web Page": "Web Page",
        "Web Page Settings": "Web Page Settings",
        "Website": "Website",
        "Week": "Week",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.",
        "Welcome": "Welcome",
        "Welcome back": "Welcome back",
        "Welcome to {page}": "Welcome to {page}",
        "What is my size?": "What is my size?",
        "When enabled, an email will be sent to the user, letting them know their account has been created.": "When enabled, an email will be sent to the user, letting them know their account has been created.",
        "Whoops!": "Whoops!",
        "with a total of {totalHours} hours.": "with a total of {totalHours} hours.",
        "With gradient": "With gradient",
        "Work Houres Per Week": "Work Houres Per Week",
        "Yes": "Yes",
        "You are receiving this email because we received a password reset request for your account.": "You are receiving this email because we received a password reset request for your account.",
        "You are subscribed to the newsletter": "You are subscribed to the newsletter",
        "Your account": "Your account",
        "Your account for {siteName}": "Your account for {siteName}",
        "Your account is inactive. Please contact support.": "Your account is inactive. Please contact support.",
        "Your account is inactive. Please contact support. To use the website as a guest, please refresh the page.": "Your account is inactive. Please contact support. To use the website as a guest, please refresh the page.",
        "Your current locale is {locale}": "Your current locale is {locale}",
        "Your experience on this site is enhanced by the use of cookies.": "Your experience on this site is enhanced by the use of cookies.",
        "Your last login was {relativeTime} on {dateTime}": "Your last login was {relativeTime} on {dateTime}",
        "Your Order": "Your Order",
        "Your order": "Your order",
        "Your order was created.": "Your order was created.",
        "Your Profile": "Your Profile",
        "Your shopping cart is empty": "Your shopping cart is empty",
        "YouTube": "YouTube",
        "Youtube link": "Youtube link",
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "accepted_if": "The {attribute} must be accepted when {other} is {value}.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "ascii": "The {attribute} must only contain single-byte alphanumeric characters and symbols.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "array": "The {attribute} must have between {min} and {max} items.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "numeric": "The {attribute} must be between {min} and {max}.",
                "string": "The {attribute} must be between {min} and {max} characters."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "current_password": "The password is incorrect.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "decimal": "The {attribute} must have {decimal} decimal places.",
            "declined": "The {attribute} must be declined.",
            "declined_if": "The {attribute} must be declined when {other} is {value}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "doesnt_end_with": "The {attribute} may not end with one of the following: {values}.",
            "doesnt_start_with": "The {attribute} may not start with one of the following: {values}.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "enum": "The selected {attribute} is invalid.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field is required.",
            "gt": {
                "array": "The {attribute} must have more than {value} items.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "numeric": "The {attribute} must be greater than {value}.",
                "string": "The {attribute} must be greater than {value} characters."
            },
            "gte": {
                "array": "The {attribute} must have {value} items or more.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "string": "The {attribute} must be greater than or equal {value} characters."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lowercase": "The {attribute} must be lowercase.",
            "lt": {
                "array": "The {attribute} must have less than {value} items.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "numeric": "The {attribute} must be less than {value}.",
                "string": "The {attribute} must be less than {value} characters."
            },
            "lte": {
                "array": "The {attribute} must not have more than {value} items.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "numeric": "The {attribute} must be less than or equal {value}.",
                "string": "The {attribute} must be less than or equal {value} characters."
            },
            "mac_address": "The {attribute} must be a valid MAC address.",
            "max": {
                "array": "The {attribute} may not have more than {max} items.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "numeric": "The {attribute} may not be greater than {max}.",
                "string": "The {attribute} may not be greater than {max} characters."
            },
            "max_digits": "The {attribute} must not have more than {max} digits.",
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "array": "The {attribute} must have at least {min} items.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "numeric": "The {attribute} must be at least {min}.",
                "string": "The {attribute} must be at least {min} characters."
            },
            "min_digits": "The {attribute} must have at least {min} digits.",
            "missing": "The {attribute} field must be missing.",
            "missing_if": "The {attribute} field must be missing when {other} is {value}.",
            "missing_unless": "The {attribute} field must be missing unless {other} is {value}.",
            "missing_with": "The {attribute} field must be missing when {values} is present.",
            "missing_with_all": "The {attribute} field must be missing when {values} are present.",
            "multiple_of": "The {attribute} must be a multiple of {value}.",
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": {
                "letters": "The {attribute} must contain at least one letter.",
                "mixed": "The {attribute} must contain at least one uppercase and one lowercase letter.",
                "numbers": "The {attribute} must contain at least one number.",
                "symbols": "The {attribute} must contain at least one symbol.",
                "uncompromised": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}."
            },
            "present": "The {attribute} field must be present.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "prohibits": "The {attribute} field prohibits {other} from being present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "array": "The {attribute} must contain {size} items.",
                "file": "The {attribute} must be {size} kilobytes.",
                "numeric": "The {attribute} must be {size}.",
                "string": "The {attribute} must be {size} characters."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "ulid": "The {attribute} must be a valid ULID.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "uppercase": "The {attribute} must be uppercase.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "attributes": {
                "address": "address",
                "age": "age",
                "amount": "amount",
                "area": "area",
                "available": "available",
                "birthday": "birthday",
                "body": "body",
                "city": "city",
                "content": "content",
                "country": "country",
                "created_at": "created at",
                "creator": "creator",
                "current_password": "current password",
                "date": "date",
                "date_of_birth": "date of birth",
                "day": "day",
                "deleted_at": "deleted at",
                "description": "description",
                "district": "district",
                "duration": "duration",
                "email": "email",
                "excerpt": "excerpt",
                "filter": "filter",
                "first_name": "first name",
                "gender": "gender",
                "group": "group",
                "hour": "hour",
                "image": "image",
                "last_name": "last name",
                "lesson": "lesson",
                "line_address_1": "line address 1",
                "line_address_2": "line address 2",
                "message": "message",
                "middle_name": "middle name",
                "minute": "minute",
                "mobile": "mobile",
                "month": "month",
                "name": "name",
                "national_code": "national code",
                "number": "number",
                "password": "password",
                "password_confirmation": "password confirmation",
                "phone": "phone",
                "photo": "photo",
                "postal_code": "postal code",
                "price": "price",
                "province": "province",
                "recaptcha_response_field": "recaptcha response field",
                "remember": "remember",
                "restored_at": "restored at",
                "result_text_under_image": "result text under image",
                "role": "role",
                "second": "second",
                "sex": "sex",
                "short_text": "short text",
                "size": "size",
                "state": "state",
                "street": "street",
                "student": "student",
                "subject": "subject",
                "teacher": "teacher",
                "terms": "terms",
                "test_description": "test description",
                "test_locale": "test locale",
                "test_name": "test name",
                "text": "text",
                "time": "time",
                "title": "title",
                "updated_at": "updated at",
                "username": "username",
                "year": "year",
                "role_id": "role",
                "shipping_address.street_name": "street name",
                "shipping_address.street_number": "street number",
                "shipping_address.street_number_addition": "street number addition",
                "shipping_address.postcode": "postcode",
                "shipping_address.city": "city",
                "shipping_address.country": "country",
                "billing_address.street_name": "street name",
                "billing_address.street_number": "street number",
                "billing_address.street_number_addition": "street number addition",
                "billing_address.postcode": "postcode",
                "billing_address.city": "city",
                "billing_address.country": "country",
                "shipping_rate_id": "shipping rate",
                "consultant_id": "consultant"
            }
        },
        "routes": {
            "blog": "blog",
            "about-us": "about-us",
            "general-terms": "general-terms",
            "privacy-policy": "privacy-policy",
            "cookie-policy": "cookie-policy",
            "contact": "contact",
            "products": "products"
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "password": "The password is incorrect.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "pagination": {
            "next": "Next &raquo;",
            "previous": "&laquo; Previous"
        },
        "http-statuses": {
            "0": "Unknown Error",
            "100": "Continue",
            "101": "Switching Protocols",
            "102": "Processing",
            "200": "OK",
            "201": "Created",
            "202": "Accepted",
            "203": "Non-Authoritative Information",
            "204": "No Content",
            "205": "Reset Content",
            "206": "Partial Content",
            "207": "Multi-Status",
            "208": "Already Reported",
            "226": "IM Used",
            "300": "Multiple Choices",
            "301": "Moved Permanently",
            "302": "Found",
            "303": "See Other",
            "304": "Not Modified",
            "305": "Use Proxy",
            "307": "Temporary Redirect",
            "308": "Permanent Redirect",
            "400": "Bad Request",
            "401": "Unauthorized",
            "402": "Payment Required",
            "403": "Forbidden",
            "404": "Not Found",
            "405": "Method Not Allowed",
            "406": "Not Acceptable",
            "407": "Proxy Authentication Required",
            "408": "Request Timeout",
            "409": "Conflict",
            "410": "Gone",
            "411": "Length Required",
            "412": "Precondition Failed",
            "413": "Payload Too Large",
            "414": "URI Too Long",
            "415": "Unsupported Media Type",
            "416": "Range Not Satisfiable",
            "417": "Expectation Failed",
            "418": "I'm a teapot",
            "419": "Session Has Expired",
            "421": "Misdirected Request",
            "422": "Unprocessable Entity",
            "423": "Locked",
            "424": "Failed Dependency",
            "425": "Too Early",
            "426": "Upgrade Required",
            "428": "Precondition Required",
            "429": "Too Many Requests",
            "431": "Request Header Fields Too Large",
            "444": "Connection Closed Without Response",
            "449": "Retry With",
            "451": "Unavailable For Legal Reasons",
            "499": "Client Closed Request",
            "500": "Internal Server Error",
            "501": "Not Implemented",
            "502": "Bad Gateway",
            "503": "Maintenance Mode",
            "504": "Gateway Timeout",
            "505": "HTTP Version Not Supported",
            "506": "Variant Also Negotiates",
            "507": "Insufficient Storage",
            "508": "Loop Detected",
            "509": "Bandwidth Limit Exceeded",
            "510": "Not Extended",
            "511": "Network Authentication Required",
            "520": "Unknown Error",
            "521": "Web Server is Down",
            "522": "Connection Timed Out",
            "523": "Origin Is Unreachable",
            "524": "A Timeout Occurred",
            "525": "SSL Handshake Failed",
            "526": "Invalid SSL Certificate",
            "527": "Railgun Error",
            "598": "Network Read Timeout Error",
            "599": "Network Connect Timeout Error",
            "unknownError": "Unknown Error"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have emailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset link is (no longer) invalid.",
            "user": "We have emailed your password reset link!"
        }
    },
    "nl": {
        "(and {count} more error)": "(en {count} andere foutmelding)",
        "(and {count} more errors)": "(en {count} andere foutmeldingen)",
        "* Required fields": "* Verplichte velden",
        "+": "+",
        "+1": "+1",
        "-": "-",
        "6-Digit code": "6-cijferige code",
        "404 - Page not found": "404 - Pagina niet gevonden",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{Attribute} is invalid": "{Attribute} is ongeldig",
        "{count} characters": "{count} characters",
        "{employeeName} has too much overtime": "{employeeName} heeft te veel overuren",
        "{model} {action}": "{model} {action}",
        "{variant} image": "{variant} afbeelding",
        "About HGBI": "Over HGBI",
        "About us": "Over ons",
        "Account": "Account",
        "Activated": "Geactiveerd",
        "activated": "geactiveerd",
        "Active": "Actief",
        "Added product to cart": "Product toegevoegd aan winkelwagen",
        "Add leave registration": "Verlofregistratie toevoegen",
        "Add menu item": "Menu-item toevoegen",
        "Add message": "Bericht toevoegen",
        "add one": "toevoegen",
        "Add page block": "Pagina blok toevoegen",
        "Add Time Off": "Uren wijzigen",
        "Add to favorites": "Toevoegen aan favorieten",
        "Add to shopping cart": "Toevoegen aan winkelwagentje",
        "Admin": "Admin",
        "Administrator": "Beheerder",
        "All": "Alle",
        "All {models}": "Alle {modellen}",
        "All blogs": "Alle blogs",
        "Allow cookies": "Cookies toestaan",
        "All Products": "Alle producten",
        "All products": "Alle producten",
        "All questions": "Alle vragen",
        "All rights reserved.": "Alle rechten voorbehouden.",
        "Alt text": "Alt-tekst",
        "Amount": "Bedrag",
        "Amount of products": "Aantal producten",
        "A new leave registration was created": "Er is een nieuwe verlofregistratie aangemaakt",
        "A new leave registration was created.": "Er is een nieuwe verlofregistratie aangemaakt.",
        "Answer": "Antwoord",
        "any": "enkele",
        "A piece": "Per stuk",
        "Apply discount code": "Kortingscode toepassen",
        "Approved": "Goedgekeurd",
        "App Settings": "App-instellingen",
        "Author": "Auteur",
        "Back": "Terug",
        "Back and mark as done": "Terug en markeer als klaar",
        "Back and release": "Terug en loslaten",
        "Back to home": "Terug naar home",
        "Back to the shopping cart": "Terug naar winkelmand",
        "Backup codes": "Back-up codes",
        "Balance": "Saldo",
        "Banner": "Banner",
        "Beige": "Beige",
        "Billing address": "Factuuradres",
        "Black": "Zwart",
        "Blog": "Blog",
        "Blogs": "Blogs",
        "Blog Settings": "Blog Instellingen",
        "Blue": "Blauw",
        "Blue Button": "Blauwe knop",
        "Brass": "Messing",
        "Bronze": "Brons",
        "Brown": "Bruin",
        "BTW number": "BTW-nummer",
        "Bullet point": "Bullet point",
        "Bullet points": "Bullet points",
        "Button link": "Knop link",
        "Buttons": "Knoppen",
        "Button text": "Knoptekst",
        "By subscribing you agree to receive our newsletter.": "Door je in te schrijven ga je akkoord met het ontvangen van onze nieuwsbrief.",
        "Categories": "Categorieën",
        "Category": "Categorie",
        "Category Settings": "Categorie Instellingen",
        "Category slider": "Categorie slider",
        "Change": "Verandering",
        "Change password": "Wachtwoord wijzigen",
        "Change time off {name}": "Verlof registratie wijzigen {name}",
        "Checkout": "Kassa",
        "Checkout page": "Afrekenpagina",
        "Choose link type": "Type link kiezen",
        "City": "Stad",
        "Client": "Cliënt",
        "Clients": "Cliënten",
        "Close conversation": "sluit gesprek",
        "closed": "gesloten",
        "CoC": "KvK",
        "Coc:": "KvK",
        "Coc number": "Kvk nummer",
        "Code": "Code",
        "code": "code",
        "Color": "Kleur",
        "Colour": "Kleur",
        "Colour code": "Kleurcode",
        "Colour name": "Kleur naam",
        "Comment": "Commentaar",
        "Commerce settings": "E-commerce instellingen",
        "Company details": "Bedrijfsgegevens",
        "Company name": "Bedrijfsnaam",
        "Complete": "Compleet",
        "Concept in only visible to logged in users, draft is not visible to anyone": "Concept in alleen zichtbaar voor ingelogde gebruikers, draft is voor niemand zichtbaar",
        "Conditional Page link": "Voorwaardelijke paginalink",
        "Confirm Password": "Wachtwoord bevestigen",
        "Confirm password": "Wachtwoord bevestigen",
        "Consultant": "Consultant",
        "Contact": "Contact",
        "Contact form": "Contactformulier",
        "Contact information": "Contactinformatie",
        "Content": "Inhoud",
        "Content (Collapsible)": "Inhoud (Inklapbaar)",
        "Contract": "Contract",
        "Conversation": "Gesprek",
        "Conversations": "Gesprekken",
        "Copper": "Koper",
        "Coupon": "Coupon",
        "Coupon applied": "Coupon toegepast",
        "Coupon can no longer be used": "Coupon kan niet langer worden gebruikt",
        "Coupon not found": "Coupon niet gevonden",
        "Coupons": "Coupons",
        "create": "maak aan",
        "Create {model}": "Maak {model}",
        "Create an account": "Een account aanmaken",
        "Create a password": "Maak een wachtwoord aan",
        "Create contract": "Contract maken",
        "created": "aangemaakt",
        "Created at": "Gemaakt op",
        "Create follow-up": "Follow-up creëren",
        "Create leave registration": "Verlofregistratie aanmaken",
        "Create order": "Bestel",
        "Currently no stock": "Momenteel geen voorraad",
        "Current Password": "Huidig wachtwoord",
        "Current password": "Huidig wachtwoord",
        "Customers will be shown the cheapest applicable shipping rate from each group. Currently existing groups: {groups}": "Klanten krijgen de goedkoopste toepasselijke verzendkosten van elke groep te zien. Momenteel bestaande groepen: {groups}",
        "Dangerously high": "Gevaarlijk hoog",
        "Dangerously high explanation": "Gevaarlijk hoge uitleg",
        "Dangerously Low": "Gevaarlijk laag",
        "Dangerously low explanation": "Gevaarlijk lage verklaring",
        "Dashboard": "Dashboard",
        "Date": "Datum",
        "Date of birth": "Geboortedatum",
        "Deactivate": "Deactiveer",
        "Deactivated": "gedeactiveerd",
        "deactivated": "gedeactiveerd",
        "Decline cookies": "Cookies weigeren",
        "Default consultant": "Standaard consulent",
        "De laatste trends": "De laatste trends",
        "Delete": "Verwijder",
        "delete": "verwijder",
        "deleted": "verwijderd",
        "Delivery options": "Bezorgopties",
        "Delivery or pick up?": "Bezorg of afhalen?",
        "Description": "Beschrijving",
        "description": "beschrijving",
        "Desktop": "Desktop",
        "Details": "Details",
        "Disable swiper": "Swiper uitschakelen",
        "Disabling a coupon will prevent it from being used": "Als je een coupon uitschakelt, kan deze niet meer worden gebruikt.",
        "Disclaimer": "Disclaimer",
        "Discount": "Korting",
        "Discount active": "Korting actief",
        "Discount code": "Kortingscode",
        "done": "klaar",
        "Don’t have access to your authenticator device ?": "Hebt u geen toegang tot uw authenticatietoestel?",
        "Down": "Omlaag",
        "Download app": "App downloaden",
        "Download backup codes and continue.": "Download back-up codes en ga verder.",
        "Download invoice": "Factuur downloaden",
        "Due at": "Toe doen op",
        "E-mail": "E-mail",
        "e-mail": "E-mail",
        "Edit": "Bewerk",
        "edit": "bewerk",
        "Edit {model}": "{model} bewerken",
        "Edit account": "Account bewerken",
        "Edit leave registration": "Verlofregistratie bewerken",
        "Email": "E-mail",
        "email": "e-mail",
        "Email address": "E-mailadres",
        "Employee": "Werknemer",
        "employee": "werknemer",
        "Employees": "Werknemers",
        "Employers": "Werkgevers",
        "employers": "werkgevers",
        "End": "Einde",
        "Enter your e-mail and we send you a reset link": "Voer je e-mail in en we sturen je een resetlink",
        "Excerpt": "Uittreksel",
        "excerpt": "uittreksel",
        "External Link": "Externe link",
        "External Test": "Externe test",
        "External Tester": "Extern testapparaat",
        "Facebook": "Facebook",
        "Facebook link": "Facebook-link",
        "Faq": "Faq",
        "Faqs": "Vragen",
        "Fast delivery": "Snelle levering",
        "Favorites": "Favorieten",
        "favorites": "Favorieten",
        "Featured Image": "Uitgelichte afbeelding",
        "Female": "Vrouw",
        "Fill in the 6-digit code": "Vul de 6-cijferige code in",
        "Fill in this code in the app.": "Vul deze code in de app in.",
        "Filter": "Filter",
        "Filters": "Filters",
        "First Name": "Voornaam",
        "First name": "Voornaam",
        "First things first": "First things first",
        "Fixed": "Vast",
        "Focal point": "Brandpunt",
        "Follow up to": "Vervolg op",
        "Follow up tot": "Opvolging tot",
        "Follow us": "Volg ons",
        "Follow us on": "Volg ons op",
        "Forbidden": "Geen toegang",
        "Forgot password": "Wachtwoord vergeten",
        "Forgot password?": "Wachtwoord vergeten?",
        "Forgotten password?": "Wachtwoord vergeten?",
        "Forgot your password?": "Wachtwoord vergeten?",
        "Forgot your password? Send a password reset link": "Wachtwoord vergeten? Stuur een wachtwoord reset link",
        "Form submission": "Formulier indienen",
        "Form submissions": "Formulier",
        "Free": "Gratis",
        "FREE SHIPPING WITH ORDERS OVER €75": "GRATIS VERZENDING BIJ BESTELLINGEN BOVEN €75",
        "Frequently asked questions": "Veelgestelde vragen",
        "From the authenticator app": "Vanuit de authenticator app",
        "Gain muscle product": "Spierversterkend product",
        "Gallery": "Galerie",
        "General slider": "Algemene slider",
        "Generate variants": "Varianten genereren",
        "Gold": "Goud",
        "Google Authenticator": "Google Authenticator",
        "Google Maps URL": "Google Maps URL",
        "Google maps URL": "Google maps URL",
        "Go to page {page}": "Ga naar pagina {page}",
        "GRATIS VERZENDING BIJ BESTEDING VAN € 75,-": "GRATIS VERZENDING BIJ BESTEDING VAN € 75,-",
        "Green": "Groen",
        "Grey": "Grijs",
        "Group": "Groep",
        "Has image": "Heeft afbeelding",
        "Heading": "Rubriek",
        "Hello": "Hallo",
        "Hello!": "Hallo!",
        "Hello {name}": "Hallo {name}",
        "Hide on page": "Verbergen op pagina",
        "High": "Hoog",
        "Highest": "Hoogste",
        "Highest quality": "Hoogste kwaliteit",
        "High explanation": "Hoge uitleg",
        "Hits": "Hits",
        "Home": "Home",
        "Hoogste kwaliteit": "Hoogste kwaliteit",
        "hour": "uur",
        "Hours": "Uren",
        "Hours first day": "Uren eerste dag",
        "Hours last day": "Uren laatste dag",
        "Hours per week": "Uren per week",
        "I am new here": "Ik ben nieuw hier",
        "Icon": "Pictogram",
        "If checked, the image will be full width.": "Als deze optie is aangevinkt, wordt de afbeelding op volledige breedte weergegeven.",
        "If checked, the image will have a gradient. Only has effect if \"Image full width\" is checked.": "Als deze optie is aangevinkt, heeft de afbeelding een kleurovergang. Heeft alleen effect als \"Afbeelding volledige breedte\" is aangevinkt.",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "Indien ingeschakeld, en er is geen afbeelding geupload, dan wordt de banner van de homepage getoond.",
        "If left empty, the page title will be used.": "Als deze leeg blijft, wordt de paginatitel gebruikt.",
        "If left empty, the title will be used.": "Als deze leeg blijft, wordt de titel gebruikt.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "Als je problemen hebt met de \"{actionText}\" knop, kopieer en plak de URL hierondernin je webbrowser:",
        "If you did not create an account, no further action is required.": "Als je geen account hebt aangemaakt hoef je verder niets te doen.",
        "If you did not request a password reset, no further action is required.": "Als je geen wachtwoordherstel hebt aangevraagd, hoef je verder niets te doen.",
        "I have saved the backup codes": "Ik heb de back-up codes opgeslagen",
        "Image": "Afbeelding",
        "Image CTA": "Afbeelding CTA",
        "Image full width": "Afbeelding volledige breedte",
        "Images": "Afbeeldingen",
        "Include inactive": "Inclusief inactive",
        "Include Inactive": "Inclusief inactive",
        "Including VAT": "Inclusief BTW",
        "Index Page": "Indexpagina",
        "Inloggen": "Inloggen",
        "In shoppingcart": "In winkelwagen",
        "Inspection": "Inspectie",
        "Instagram": "Instagram",
        "Instagram link": "Instagram-link",
        "Instruction title": "Titel van de instructie",
        "Internal comment": "Interne opmerking",
        "Internal comments": "Interne opmerkingen",
        "Introduction": "Inleiding",
        "Invalid phone number": "Ongeldig telefoonnummer",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "Het wordt aanbevolen het {attribute} tussen {min} en {max} tekens lang te houden.",
        "Kind regards,": "Met vriendelijke groet,",
        "KVK number": "KVK-nummer",
        "Label": "Label",
        "Label Button": "Labelknop",
        "Language": "Taal",
        "Last message on": "Laatste bericht op",
        "Last Name": "Achternaam",
        "Last name": "Achternaam",
        "Last refresh was {quantity} {unit} ago": "Laaste verversing was {quantity} {unit} geleden",
        "Leave empty for no end date": "Leeg laten voor geen einddatum",
        "Leave empty to generate a random code. Not editable after creation.": "Leeg laten om een willekeurige code te genereren. Kan niet worden bewerkt na aanmaak.",
        "Leave empty to use 'Related products'": "Leeg laten om 'Gerelateerde producten' te gebruiken",
        "Leave empty to use parent details": "Leeg laten om hoofdgegevens te gebruiken",
        "Leave registratie": "Verlofregistratie",
        "Leave Registration": "Verlofregistratie",
        "Leave registration": "Verlofregistratie",
        "leave registration": "verlofregistratie",
        "Leave registrations": "Verlofregistraties",
        "Link Button": "Koppelingsknop",
        "Linkedin link": "Linkedin link",
        "Log in": "Inloggen",
        "Login": "Inloggen",
        "Login / Register": "Inloggen / Registreren",
        "Login to your account in our secure environment": "Log in op je account in onze beveiligde omgeving",
        "Login with a recovery code.": "Aanmelden met een herstelcode.",
        "Logout": "Uitloggen",
        "Lose weight product": "Product om af te vallen",
        "Low": "Laag",
        "Lowest": "Laagste",
        "Low explanation": "Lage uitleg",
        "Loyal customer": "Trouwe klant",
        "Made by:": "Gerealiseerd door:",
        "Maintain muscle product": "Spierproduct onderhouden",
        "Main variant": "Hoofdvariant",
        "Make main": "Hoofdvariant maken",
        "Make your decision:": "Neem een beslissing:",
        "Male": "Man",
        "Mark as done": "Markeren als gedaan",
        "Max users": "Maximaal aantal gebruikers",
        "Medical": "Medisch",
        "Medium": "Medium",
        "Meer informatie": "Meer informatie",
        "Menu": "Menu",
        "Menu items": "Menu-items",
        "Menus": "Menu's",
        "Message": "Bericht",
        "Messages": "Berichten",
        "Metric Name": "Meetwaarden naam",
        "Microsoft Authenticator": "Microsoft Authenticator",
        "Minimal order amount": "Minimaal bestelbedrag",
        "minute": "minuut",
        "minutes": "minuten",
        "Mobile": "Mobiel",
        "More": "Meer",
        "More information": "Meer informatie",
        "More settings can be set in the Page Block Settings page": "Meer instellingen kunnen worden ingesteld op de pagina Instellingen Paginablokken",
        "Most sold": "Meest verkocht",
        "Mute client": "Mute client",
        "Muted": "Gedempt",
        "muted": "gedempt",
        "My leave registrations": "Mijn verlofregistraties",
        "My Time registrations": "Mijn tijd registraties",
        "Name": "Naam",
        "name": "naam",
        "Name client": "Naam cliënt",
        "Newest": "Nieuwste",
        "New Password": "Nieuw wachtwoord",
        "New password": "Nieuw wachtwoord",
        "New URL": "Nieuwe URL",
        "Next": "Volgende",
        "No": "Nee",
        "No favorites yet": "Nog geen favorieten",
        "No items": "Geen items",
        "None": "Geen",
        "No options have been created yet": "Er zijn nog geen opties gemaakt",
        "No orders yet": "Nog geen bestellingen",
        "No products found. Try again": "Geen producten gevonden. Opnieuw proberen",
        "No results found": "Geen resultaten gevonden",
        "No settings available": "Geen instellingen beschikbaar",
        "Not available": "Niet beschikbaar",
        "Not available in this combination": "Niet beschikbaar in deze combinatie",
        "Not editable after creation.": "Niet bewerkbaar na aanmaak.",
        "Not Found": "Niet gevonden",
        "Notifications": "Meldingen",
        "Not implemented yet": "Nog niet geïmplementeerd",
        "Number": "Aantal",
        "Number of hours": "Aantal uren",
        "of": "van",
        "Old URL": "Oude URL",
        "Only send a warning once every x days.": "Stuur slechts eenmaal per x dagen een waarschuwing.",
        "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.": "Op uw telefoon of tablet met camera. Wij kunnen u adviseren om {google} of {microsoft} te gebruiken.",
        "Openings hours": "Openingstijden",
        "Open in new tab": "In nieuw tabblad openen",
        "Open the authenticator app, add a new entry and scan the QR code": "Open de authenticator-app, voeg een nieuw item toe en scan de QR-code",
        "Options": "Opties",
        "Orange": "Oranje",
        "Orange Button": "Oranje knop",
        "Order": "Bestel",
        "Order now": "Bestel nu",
        "Order number": "Bestelnummer",
        "Orders": "Bestellingen",
        "Order was created": "Order is aangemaakt",
        "Overtime": "Overuren",
        "Overtime Balance": "Overuren Saldo",
        "Overtime Balance: {balance}": "Overuren Saldo {balance}",
        "Overtime warning interval": "Overuren waarschuwings interval",
        "Overtime warning threshold": "Overuren waarschuwings drempel",
        "Overview": "Overzicht",
        "Own leave registrations": "Eigen verlofregistraties",
        "Page": "Pagina",
        "Page blocks": "Pagina blokken",
        "Page block settings": "Instellingen Paginablokken",
        "Page button": "Pagina knop",
        "Page Content": "Pagina-inhoud",
        "Page Expired": "Pagina niet meer geldig",
        "Page link": "Pagina link",
        "Pages": "Pagina's",
        "Page Title": "Pagina Titel",
        "Pagination Navigation": "Paginanavigatie",
        "Paragraph": "Paragraaf",
        "Parent category": "Hoofdcategorie",
        "Password": "Wachtwoord",
        "Password reset": "Wachtwoord opnieuw instellen",
        "Password reset link has been sent": "Resetlink voor wachtwoord is verzonden",
        "Paste or type the code we sent a code to your e-mail.": "Plak of typ de code die we naar je e-mail hebben gestuurd.",
        "Paste or type the code we sent a code to your phone number.": "Plak of typ de code die we naar je telefoonnummer hebben gestuurd.",
        "Payment": "Betaling",
        "Payment Required": "Betaling Vereist",
        "Payments": "Betalingen",
        "Percentage": "Percentage",
        "Perfecte pasvorm": "Perfecte pasvorm",
        "Perfect fit": "Perfecte pasvorm",
        "Permanent": "Permanent",
        "Phone": "Telefoon",
        "Phone Number": "Telefoonnummer",
        "Phone number": "Telefoonnummer",
        "phone number": "telefoonnummer",
        "phone_number": "telefoonnummer",
        "Pick-up": "Afhalen",
        "Pink": "Roze",
        "Place order": "Bestelling plaatsen",
        "Please click the button below to verify your email address.": "Klik op de knop hieronder om je e-mailadres te verifiëren.",
        "Please confirm access to your account by entering one of your emergency recovery codes.": "Bevestig de toegang tot je account door een van je noodherstelcodes in te voeren.",
        "Please confirm access to your account by entering the authentication code provided by your authenticator application.": "Bevestig de toegang tot je account door de verificatiecode in te voeren die je van je authenticatietoepassing hebt gekregen.",
        "Please use the link in your email to view your order.": "Gebruik de link in je e-mail om je bestelling te bekijken.",
        "Postal code": "Postcode",
        "Postcode": "Postcode",
        "Premium quality": "Premium kwaliteit",
        "Previous": "Vorige",
        "Price": "Prijs",
        "Price (high to low)": "Prijs (hoog naar laag)",
        "Price (low to high)": "Prijs (laag naar hoog)",
        "Price: High - Low": "Prijs: Hoog - Laag",
        "Price: Low - High": "Prijs: Laag - Hoog",
        "Print": "Afdrukken",
        "Priority": "Prioriteit",
        "Privacy declaration": "Privacyverklaring",
        "Privacy page": "Privacy pagina",
        "Product": "Product",
        "Product details": "Productgegevens",
        "Product information": "Productinformatie",
        "Product option": "Product optie",
        "Product options": "Productopties",
        "Products": "Producten",
        "products": "Producten",
        "Product Settings": "Productinstellingen",
        "Product slider": "Product slider",
        "Publish date": "Datum publicatie",
        "Published At": "Gepubliceerd op",
        "Published from": "Gepubliceerd vanaf",
        "Published until": "Gepubliceerd tot",
        "Purple": "Paars",
        "Quantity": "Aantal",
        "Question": "Vraag",
        "Randimize image": "Randimaliseer afbeelding",
        "Randomize image": "Afbeelding kleuren willekeurig maken",
        "Reactivate": "Reactiveren",
        "Read more": "Meer lezen",
        "Recommended products": "Aanbevolen producten",
        "Red": "Rood",
        "Redirect": "omleiden",
        "Redirects": "Omleidingen",
        "Regards": "Met vriendelijke groet",
        "Register": "Registreren",
        "Register to your account in our secure environment": "Registreer je account in onze beveiligde omgeving",
        "Rejected": "Afgewezen",
        "Related products": "Gerelateerde producten",
        "Remember me": "Herinner mij",
        "Remove": "Verwijder",
        "Remove discount code": "Kortingscode verwijderen",
        "Remove from favorites": "Verwijderen uit favorieten",
        "remove one": "verwijderen",
        "remove product": "product verwijderen",
        "Remove product from cart": "Product uit winkelwagentje verwijderen",
        "Reopened subtask": "Heropende subtaak",
        "Resend": "Opnieuw versturen",
        "Resend {attribute}": "Verstuur {attribute} opnieuw",
        "Resend in": "Opnieuw verzenden in",
        "Reserved By": "Gereserveerd door",
        "Reset Password": "Wachtwoord herstellen",
        "Reset password": "Wachtwoord opnieuw instellen",
        "Reset Password Notification": "Wachtwoordherstel notificatie",
        "Results": "Resultaten",
        "results": "resultaten",
        "Results are shared": "De resultaten worden gedeeld",
        "Reviews": "Beoordelingen",
        "RGB color": "RGB-kleur",
        "Rights": "Rechten",
        "Role": "Rol",
        "role": "rol",
        "Roles": "Rollen",
        "Saldo: {saldo}": "Saldo: {saldo}",
        "Sales": "Verkoop",
        "Sampled at": "Afgenomen op",
        "Save": "Opslaan",
        "Save in favorites": "Opslaan in favorieten",
        "Save these codes on a secure place if you can’t get access with your Authenticator app.": "Bewaar deze codes op een veilige plek als je geen toegang krijgt met je Authenticator app.",
        "Scanning not possible?": "Scannen niet mogelijk?",
        "Scanning not possible? Fill in this code in the app.": "Scannen niet mogelijk? Vul deze code in de app in.",
        "Scan the QR code": "Scan de QR-code",
        "Search": "Zoek op",
        "Search {model}": "Zoeken {model}",
        "sec": "sec",
        "Secure payment": "Veilige betaling",
        "Select": "Selecteer",
        "Select a {model}": "Selecteer een {model}",
        "Select a page": "Selecteer een pagina",
        "Select a template to add the corresponding blocks.": "Selecteer een sjabloon om de bijbehorende blokken toe te voegen.",
        "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created": "Selecteer een template om de bijbehorende blokken toe te voegen. Let op: dit kan niet worden gewijzigd nadat de pagina is aangemaakt.",
        "Select a test": "Selecteer een test",
        "Select a type": "Selecteer een type",
        "Selection aid": "Keuzehulp",
        "selection aid": "keuzehulp",
        "Select the number of hours": "Selecteer het aantal uren",
        "Select the type": "Selecteer het type",
        "Send": "Stuur",
        "Send and return": "Verzenden en retourneren",
        "Send a password reset link": "Stuur een link om het wachtwoord te resetten",
        "Send a warning when the overtime is above this threshold.": "Stuur een waarschuwing als het overuren boven deze drempel komt.",
        "Send e-mail": "Stuur e-mail",
        "Send email": "Stuur e-mail",
        "Send link": "Link verzenden",
        "Send verification {attribute}": "Verificatie verzenden {attribuut}",
        "Seo Settings": "Seo-instellingen",
        "SEO Settings for {lang}": "SEO instellingen voor {lang}",
        "SEO title": "SEO titel",
        "Server Error": "Server fout",
        "Service": "Service",
        "Service Unavailable": "Website onbeschikbaar",
        "Set  password": "Wachtwoord instellen",
        "Sets": "Zet",
        "Settings": "Instellingen",
        "Set Two Factor Authentication": "Twee Factor Authenticatie instellen",
        "Set your secure password below": "Stel hieronder uw veilige wachtwoord in",
        "Sex": "Geslacht",
        "shared": "gedeeld",
        "Share results": "Resultaten delen",
        "Shipping address": "Verzendadres",
        "Shipping address same as billing address": "Verzendadres hetzelfde als factuuradres",
        "Shipping and billing address": "Verzend- & factuuradres",
        "Shipping and return information": "Informatie over verzenden en retourneren",
        "Shipping cost": "Verzendkosten",
        "Shipping method": "Verzendmethode",
        "Shipping Rate": "Verzendtarief",
        "shipping rate": "verzendtarief",
        "Shipping Rates": "Verzendtarieven",
        "Shop": "Winkel",
        "Shopping cart": "Winkelwagen",
        "shopping cart": "winkelwagen",
        "Shopping cart page": "Pagina met winkelwagentje",
        "Short variant": "Korte variant",
        "Showing": "Toont",
        "Show less": "Minder tonen",
        "Show more": "Meer tonen",
        "Show products": "Producten tonen",
        "Show rectangle": "Rechthoek weergeven",
        "Sick day": "Ziektedag",
        "sidebar": "zijbalk",
        "Sign out": "Afmelden",
        "Sign up for our newsletter": "Schrijf je in voor onze nieuwsbrief",
        "Silver": "Zilver",
        "Since it’s your first time logging in here, we’ve sent a code to your phone to verify your user profile.": "Omdat het de eerste keer is dat je hier inlogt, hebben we een code naar je telefoon gestuurd om je gebruikersprofiel te verifiëren.",
        "Size chart image": "Afbeelding maattabel",
        "Slides": "Dia's",
        "Slug": "Permalink",
        "Some time registrations are still submitted": "Sommige tijdregistraties staan nog op ingediend",
        "Some time registrations are still submitted.": "Sommige tijdregistraties staan nog op ingediend.",
        "Sort": "Sorteer",
        "Standard": "Standaard",
        "Start": "Start",
        "Started at": "Gestart op",
        "Status": "Status",
        "Statuses": "Statussen",
        "Stock": "Voorraad",
        "Street name": "Straatnaam",
        "Street number": "Huisnummer",
        "Street number addition": "Toevoeging",
        "Subject": "Onderwerp",
        "Sub menu": "Submenu",
        "Submitted": "Ingediend",
        "Subscribe": "Aanmelden",
        "SubTasks": "Subtaken",
        "Subtitle": "Ondertitel",
        "subtitle": "ondertitel",
        "Subtotal": "Subtotaal",
        "Tags": "Tags",
        "Task": "Taak",
        "Tasks": "Taken",
        "Tel": "Tel",
        "Terms and Conditions": "Algemene Voorwaarden",
        "Terms page": "Voorwaarden pagina",
        "Test": "Test",
        "test": "test",
        "Tests": "Tests",
        "Test set": "Testreeks",
        "test set": "testreeks",
        "Test sets": "Testreeksen",
        "Text": "Tekst",
        "Thank you for shopping with us!": "Bedankt voor het winkelen bij ons!",
        "Thank you for your message.": "Bedankt voor je bericht.",
        "Thank you page settings": "Instellingen bedankpagina",
        "The {attribute} has already been taken.": "Het {attribute} is al ingenomen.",
        "The {attribute} must be verified.": "Het {attribute} moet worden geverifieerd.",
        "The {attribute} must contain at least one letter.": "Het {attribute} moet minimaal één letter bevatten.",
        "The {attribute} must contain at least one number.": "Het {attribute} moet minimaal één cijfer bevatten.",
        "The {attribute} must contain at least one symbol.": "Het {attribute} moet minimaal één symbool bevatten.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "Het {attribute} moet minimaal één hoofdletter en één kleine letter bevatten.",
        "The coupon has already been used.": "De coupon is al gebruikt.",
        "The coupon has expired.": "De coupon is verlopen.",
        "The end date is required.": "De einddatum is vereist.",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "Het {attribute} is aangetroffen in een datalek. Geef een ander {attribute}.",
        "The given data was invalid.": "De gegeven data was ongeldig.",
        "The latest trends": "De nieuwste trends",
        "The password field is required when creating an account.": "Het wachtwoordveld is vereist bij het aanmaken van een account.",
        "The provided {attribute} is invalid.": "Het verstrekte {attribute} is ongeldig.",
        "The provided code was invalid.": "De verstrekte code was ongeldig.",
        "The provided password does not match your current password.": "Het opgegeven wachtwoord komt niet overeen met uw huidige wachtwoord.",
        "The provided two factor authentication code was invalid.": "De verstrekte tweefactorauthenticatiecode was ongeldig.",
        "The tests cannot be changed once the test set is created.": "De tests kunnen niet worden gewijzigd nadat de testreeks is aangemaakt.",
        "This {attribute} has already been used": "Dit {attribute} is al gebruikt",
        "This area is restricted to medical medic members only.": "Dit gebied is alleen toegankelijk voor medische leden.",
        "This area is restricted to staff members only.": "Dit gebied is alleen toegankelijk voor personeelsleden.",
        "This is your first login": "Dit is uw eerste login",
        "This link expires in {number} hours": "Deze link verloopt over {number} uur",
        "This password reset link will expire in {count} minutes.": "Deze link om je wachtwoord te herstellen verloopt over {count} minuten.",
        "This shipping rate is not applicable to your cart.": "Dit verzendtarief is niet van toepassing op je winkelwagen.",
        "This task is reserved by {userName}": "Deze task is gereserveerd door {userName}",
        "This task is reserved by {userName}.": "Deze task is gereserveerd door {userName}.",
        "Tiktoc link": "Tiktoc-link",
        "TikTok": "TikTok",
        "Time off": "Verlof",
        "Time off warning threshold": "Waarschuwingsdrempel voor verlof saldo",
        "Time registration": "Tijdregistratie",
        "time registration": "tijdregistratie",
        "Time registrations": "Tijdregistraties",
        "Times used": "Gebruikte tijden",
        "Title": "Titel",
        "title": "titel",
        "to": "tot",
        "TODO": "TODO",
        "Toggle navigation": "Schakel navigatie",
        "token": "token",
        "Too Many Requests": "Te veel serververzoeken",
        "Totaal products ({number})": "Totaal producten ({number})",
        "Total": "Totaal",
        "Total excluding VAT": "Totaal exclusief BTW",
        "Total including VAT": "Totaal inclusief BTW",
        "Total saving": "Totale besparing",
        "To use Top Chefs Two Factor Authentication is required as extra security, you can set this now": "Om Topchefs te gebruiken is Two Factor Authentication nodig als extra beveiliging, u kunt dit nu instellen",
        "Trans": "Trans",
        "Two-factor Confirmation": "Bevestiging met twee factoren",
        "Two Factor Authentication": "Authenticatie met twee factoren",
        "Two Factor Authentication - Backup codes": "Twee Factor Authenticatie - Back-up codes",
        "Two Factor Authentication is required as extra security, you can set this now": "Two Factor Authentication is vereist als extra beveiliging, dit kun je nu instellen",
        "Two factor challenge": "Tweefactorige uitdaging",
        "Type": "Type",
        "Unauthorized": "Onbevoegd",
        "Unisex": "Unisex",
        "Unmute client": "Ontdemp client",
        "unmuted": "ontdempt",
        "Up": "Omhoog",
        "Update": "Bijwerken",
        "Update account": "Account bijwerken",
        "Update contract": "Contract bijwerken",
        "updated": "bijgewerkt",
        "Update your info here.": "Werk je gegevens hier bij.",
        "Url": "Url",
        "url": "url",
        "Use an authentication code": "Een verificatiecode gebruiken",
        "User": "Gebruiker",
        "user": "gebruiker",
        "Users": "Gebruikers",
        "users": "gebruikers",
        "Use `-1` for unlimited usage": "Gebruik `-1` voor onbeperkt gebruik",
        "USPs": "USP's",
        "Uw winkelmand is leeg": "Uw winkelmand is leeg",
        "Vacation": "Vakantie",
        "Vacation Balance": "Vakantie Saldo",
        "Vacation Balance: {balance}": "Vakantie Saldo {balance}",
        "Vacation Days Per Year": "Vakantiedagen per jaar",
        "Vacation hours": "Vakantie uren",
        "Vacation Hours Per Year": "Vakantieuren per jaar",
        "Value": "Waarde",
        "Values": "Waarden",
        "Variant": "Variant",
        "Variants": "Varianten",
        "VAT": "BTW",
        "VAT:": "BTW:",
        "VAT number": "BTW-nummer",
        "Verify": "Controleer",
        "Verify {attribute}": "Verifieer {attribute}",
        "Verify Email Address": "Verifieer e-mailadres",
        "Verify your phone number": "Controleer uw telefoonnummer",
        "View": "Bekijk",
        "view": "bekijk",
        "View all": "Bekijk alles",
        "view all": "bekijk alles",
        "View employee": "Bekijk werknemer",
        "View leave registration": "Verlofregistratie bekijken",
        "View order": "Bestelling bekijken",
        "View time registrations": "Tijdregistraties bekijken",
        "Waiting for payment conformation": "Wachten op betalingsbevestiging",
        "Warning: limited stock!": "Waarschuwing: beperkte voorraad!",
        "Washing instructions": "Wasvoorschriften",
        "Was unable to find an address with this postcode and street number": "Kon geen adres vinden met deze postcode en straatnummer",
        "Web Page": "Website",
        "Web Page Settings": "Instellingen webpagina",
        "Website": "Website",
        "Week": "Week",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We hebben ons best gedaan, maar het lijkt erop dat de door u opgevraagde pagina niet (meer) bestaat of dat deze is verplaatst.",
        "Welcome": "Welkom",
        "Welcome back": "Welkom terug",
        "Welcome to {page}": "Welkom op {page}",
        "What is my size?": "Wat is mijn maat?",
        "When enabled, an email will be sent to the user, letting them know their account has been created.": "Indien ingeschakeld, wordt een e-mail naar de gebruiker gestuurd om hem te laten weten dat zijn account is aangemaakt.",
        "Which package suits me?": "Welk pakket past bij mij?",
        "White": "Wit",
        "Whoops!": "Oeps!",
        "with a total of {totalHours} hours.": "met een totaal van {totalHours} uren.",
        "With gradient": "Met kleurovergang",
        "Work day": "Werkdag",
        "Work Houres Per Week": "Werkuren per week",
        "Yellow": "Geel",
        "Yes": "Ja",
        "You are receiving this email because we received a password reset request for your account.": "Je ontvangt deze e-mail omdat we een wachtwoordherstel verzoek hebben ontvangen voor je account.",
        "You are subscribed to the newsletter": "Je bent geabonneerd op de nieuwsbrief",
        "You can contact us via the form. We look forward to hearing from you. Have you looked at the frequently asked questions? With the exception of weekends and holidays, we try to send you a response to your questions within 24 hours.": "U kunt contact met ons opnemen via het formulier. We horen graag van u. Heeft u gekeken naar de veelgestelde vragen? Met uitzondering van weekenden en feestdagen proberen we binnen 24 uur een reactie op uw vragen te sturen.",
        "Your account": "Uw account",
        "Your account for {siteName}": "Je account voor {siteName}",
        "Your account is inactive. Please contact support.": "Uw account is inactief. Neem contact op met support.",
        "Your account is inactive. Please contact support. To use the website as a guest, please refresh the page.": "Uw account is inactief. Neem contact op met support. Om de website als gast te gebruiken, vernieuwt u de pagina.",
        "Your account is not active. Please contact support.": "Je account is niet actief. Neem contact op met support.",
        "Your current locale is {locale}": "Uw huidige locale is {locale}",
        "Your experience on this site is enhanced by the use of cookies.": "Uw ervaring op deze site wordt verbeterd door het gebruik van cookies.",
        "Your last login was {relativeTime} on {dateTime}": "Uw laatste login was {relativeTime} op {dateTime}",
        "Your leave registration was rejected": "Je verlofregistratie is afgewezen",
        "Your leave registration was rejected.": "Je verlofregistratie is afgewezen.",
        "Your Order": "Uw bestelling",
        "Your order": "Uw bestelling",
        "Your order was created.": "Uw bestelling is aangemaakt.",
        "Your Profile": "Uw profiel",
        "Your shopping cart is empty": "Uw winkelwagen is leeg",
        "YouTube": "YouTube",
        "Youtube link": "Youtube-link",
        "You will receive this email because your account is ready for {siteName}": "Je ontvangt deze e-mail omdat je account klaar is voor {siteName}",
        "validation": {
            "accepted": "{Attribute} moet worden geaccepteerd.",
            "accepted_if": "{Attribute} moet worden geaccepteerd als {other} {value} is.",
            "active_url": "{Attribute} is geen geldige URL.",
            "after": "{Attribute} moet een datum na {date} zijn.",
            "after_or_equal": "{Attribute} moet een datum na of gelijk aan {date} zijn.",
            "alpha": "{Attribute} mag alleen letters bevatten.",
            "alpha_dash": "{Attribute} mag alleen letters, nummers, underscores (_) en streepjes (-) bevatten.",
            "alpha_num": "{Attribute} mag alleen letters en nummers bevatten.",
            "array": "{Attribute} moet geselecteerde elementen bevatten.",
            "ascii": "De {attribute} mag alleen alfanumerieke tekens en symbolen van één byte bevatten.",
            "before": "{Attribute} moet een datum vóór {date} zijn.",
            "before_or_equal": "{Attribute} moet een datum vóór of gelijk aan {date} zijn.",
            "between": {
                "array": "{Attribute} moet tussen {min} en {max} waardes bevatten.",
                "file": "{Attribute} moet tussen {min} en {max} kilobytes zijn.",
                "numeric": "{Attribute} moet tussen {min} en {max} zijn.",
                "string": "{Attribute} moet tussen {min} en {max} karakters zijn."
            },
            "boolean": "{Attribute} moet ja of nee zijn.",
            "confirmed": "Bevestiging van {attribute} komt niet overeen.",
            "current_password": "Huidig wachtwoord is onjuist.",
            "date": "{Attribute} moet een datum bevatten.",
            "date_equals": "{Attribute} moet een datum gelijk aan {date} zijn.",
            "date_format": "{Attribute} voldoet niet aan het formaat {format}.",
            "decimal": "De {attribute} moet {decimal} decimalen hebben.",
            "declined": "{Attribute} moet afgewezen worden.",
            "declined_if": "{Attribute} moet afgewezen worden wanneer {other} gelijk is aan {value}.",
            "different": "{Attribute} en {other} moeten verschillend zijn.",
            "digits": "{Attribute} moet bestaan uit {digits} cijfers.",
            "digits_between": "{Attribute} moet bestaan uit minimaal {min} en maximaal {max} cijfers.",
            "dimensions": "{Attribute} heeft geen geldige afmetingen.",
            "distinct": "{Attribute} heeft een dubbele waarde.",
            "doesnt_end_with": "{Attribute} mag niet eindigen met één van de volgende waarden: {values}.",
            "doesnt_start_with": "{Attribute} mag niet beginnen met één van de volgende waarden: {values}.",
            "email": "{Attribute} is geen geldig e-mailadres.",
            "ends_with": "{Attribute} moet met één van de volgende waarden eindigen: {values}.",
            "enum": "Gekozen {attribute} is ongeldig.",
            "exists": "{Attribute} bestaat niet.",
            "file": "{Attribute} moet een bestand zijn.",
            "filled": "{Attribute} is verplicht.",
            "gt": {
                "array": "{Attribute} moet meer dan {value} waardes bevatten.",
                "file": "{Attribute} moet groter zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet groter zijn dan {value}.",
                "string": "{Attribute} moet meer dan {value} tekens bevatten."
            },
            "gte": {
                "array": "{Attribute} moet {value} of meer waardes bevatten.",
                "file": "{Attribute} moet groter of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet groter of gelijk zijn aan {value}.",
                "string": "{Attribute} moet minimaal {value} tekens bevatten."
            },
            "image": "{Attribute} moet een afbeelding zijn.",
            "in": "{Attribute} is ongeldig.",
            "in_array": "{Attribute} bestaat niet in {other}.",
            "integer": "{Attribute} moet een getal zijn.",
            "ip": "{Attribute} moet een geldig IP-adres zijn.",
            "ipv4": "{Attribute} moet een geldig IPv4-adres zijn.",
            "ipv6": "{Attribute} moet een geldig IPv6-adres zijn.",
            "json": "{Attribute} moet een geldige JSON-string zijn.",
            "lowercase": "{Attribute} mag alleen kleine letters bevatten.",
            "lt": {
                "array": "{Attribute} moet minder dan {value} waardes bevatten.",
                "file": "{Attribute} moet kleiner zijn dan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner zijn dan {value}.",
                "string": "{Attribute} moet minder dan {value} tekens bevatten."
            },
            "lte": {
                "array": "{Attribute} moet {value} of minder waardes bevatten.",
                "file": "{Attribute} moet kleiner of gelijk zijn aan {value} kilobytes.",
                "numeric": "{Attribute} moet kleiner of gelijk zijn aan {value}.",
                "string": "{Attribute} moet maximaal {value} tekens bevatten."
            },
            "mac_address": "{Attribute} moet een geldig MAC-adres zijn.",
            "max": {
                "array": "{Attribute} mag niet meer dan {max} waardes bevatten.",
                "file": "{Attribute} mag niet meer dan {max} kilobytes zijn.",
                "numeric": "{Attribute} mag niet hoger dan {max} zijn.",
                "string": "{Attribute} mag niet uit meer dan {max} tekens bestaan."
            },
            "max_digits": "{Attribute} mag niet uit meer dan {max} cijfers bestaan.",
            "mimes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "mimetypes": "{Attribute} moet een bestand zijn van het bestandstype {values}.",
            "min": {
                "array": "{Attribute} moet minimaal {min} waardes bevatten.",
                "file": "{Attribute} moet minimaal {min} kilobytes zijn.",
                "numeric": "{Attribute} moet minimaal {min} zijn.",
                "string": "{Attribute} moet minimaal {min} tekens zijn."
            },
            "min_digits": "{Attribute} moet minimaal uit {min} cijfers bestaan.",
            "missing": "Het veld {attribute} moet ontbreken.",
            "missing_if": "Het veld {attribute} moet ontbreken als {other} {value} is.",
            "missing_unless": "Het veld {attribute} moet ontbreken, tenzij {other} {value} is.",
            "missing_with": "Het veld {attribute} moet ontbreken wanneer {values} aanwezig is.",
            "missing_with_all": "Het veld {attribute} moet ontbreken wanneer er {values} aanwezig zijn.",
            "multiple_of": "{Attribute} moet een veelvoud van {value} zijn.",
            "not_in": "{Attribute} is ongeldig.",
            "not_regex": "Het formaat van {attribute} is ongeldig.",
            "numeric": "{Attribute} moet een getal zijn.",
            "password": {
                "letters": "{Attribute} moet minimaal één letter bevatten.",
                "mixed": "{Attribute} moet minimaal één kleine letter en één hoofdletter bevatten.",
                "numbers": "{Attribute} moet minimaal één cijfer bevatten.",
                "symbols": "{Attribute} moet minimaal één vreemd teken bevatten.",
                "uncompromised": "Het opgegeven {attribute} komt voor in een datalek. Kies een ander {attribute}."
            },
            "present": "{Attribute} moet aanwezig zijn.",
            "prohibited": "{Attribute} is niet toegestaan.",
            "prohibited_if": "{Attribute} is niet toegestaan indien {other} gelijk is aan {value}.",
            "prohibited_unless": "{Attribute} is niet toegestaan tenzij {other} gelijk is aan {values}.",
            "prohibits": "{Attribute} is niet toegestaan in combinatie met {other}.",
            "regex": "Het formaat van {attribute} is ongeldig.",
            "required": "{Attribute} is verplicht.",
            "required_array_keys": "{Attribute} moet waardes bevatten voor {values}.",
            "required_if": "{Attribute} is verplicht indien {other} gelijk is aan {value}.",
            "required_if_accepted": "{Attribute} is verplicht indien {other} is geaccepteerd.",
            "required_unless": "{Attribute} is verplicht tenzij {other} gelijk is aan {values}.",
            "required_with": "{Attribute} is verplicht in combinatie met {values}.",
            "required_with_all": "{Attribute} is verplicht in combinatie met {values}.",
            "required_without": "{Attribute} is verplicht als {values} niet ingevuld is.",
            "required_without_all": "{Attribute} is verplicht als {values} niet ingevuld zijn.",
            "same": "{Attribute} en {other} moeten overeenkomen.",
            "size": {
                "array": "{Attribute} moet {size} waardes bevatten.",
                "file": "{Attribute} moet {size} kilobytes groot zijn.",
                "numeric": "{Attribute} moet {size} zijn.",
                "string": "{Attribute} moet {size} tekens zijn."
            },
            "starts_with": "{Attribute} moet beginnen met een van de volgende: {values}.",
            "string": "{Attribute} moet een tekst zijn.",
            "timezone": "{Attribute} moet een geldige tijdzone zijn.",
            "ulid": "De {attribute} moet een geldige ULID zijn.",
            "unique": "{Attribute} is al in gebruik.",
            "uploaded": "Het uploaden van {attribute} is mislukt.",
            "uppercase": "{Attribute} mag alleen hoofdletters bevatten.",
            "url": "{Attribute} moet een geldige URL zijn.",
            "uuid": "{Attribute} moet een geldige UUID zijn.",
            "attributes": {
                "address": "adres",
                "age": "leeftijd",
                "amount": "bedrag",
                "area": "regio",
                "available": "beschikbaar",
                "birthday": "verjaardag",
                "body": "body",
                "city": "stad",
                "content": "inhoud",
                "country": "land",
                "created_at": "aangemaakt op",
                "creator": "maker",
                "current_password": "huidig wachtwoord",
                "date": "datum",
                "date_of_birth": "geboortedatum",
                "day": "dag",
                "deleted_at": "verwijderd op",
                "description": "omschrijving",
                "district": "wijk",
                "duration": "tijdsduur",
                "email": "e-mailadres",
                "excerpt": "uittreksel",
                "filter": "filter",
                "first_name": "voornaam",
                "gender": "geslacht",
                "group": "groep",
                "hour": "uur",
                "image": "afbeelding",
                "last_name": "achternaam",
                "lesson": "les",
                "line_address_1": "adresregel 1",
                "line_address_2": "adresregel 2",
                "message": "bericht",
                "middle_name": "tweede naam",
                "minute": "minuut",
                "mobile": "mobiel",
                "month": "maand",
                "name": "naam",
                "national_code": "landcode",
                "number": "nummer",
                "password": "wachtwoord",
                "password_confirmation": "wachtwoordbevestiging",
                "phone": "telefoonnummer",
                "photo": "foto",
                "postal_code": "postcode",
                "price": "prijs",
                "province": "provincie",
                "recaptcha_response_field": "recaptcha antwoordveld",
                "remember": "onthouden",
                "restored_at": "hersteld op",
                "result_text_under_image": "antwoord tekst onder afbeelding",
                "role": "rol",
                "second": "seconde",
                "sex": "geslacht",
                "short_text": "korte tekst",
                "size": "grootte",
                "state": "staat",
                "street": "straatnaam",
                "student": "student",
                "subject": "onderwerp",
                "teacher": "docent",
                "terms": "voorwaarden",
                "test_description": "test beschrijving",
                "test_locale": "test landinstelling",
                "test_name": "test naam",
                "text": "tekst",
                "time": "tijd",
                "title": "titel",
                "updated_at": "bijgewerkt op",
                "username": "gebruikersnaam",
                "year": "jaar",
                "role_id": "rol",
                "user_id": "gebruiker",
                "user_type": "gebruikerstype",
                "shipping_address.street_name": "Straatnaam",
                "shipping_address.street_number": "Huisnummer",
                "shipping_address.street_number_addition": "Toevoeging",
                "shipping_address.postcode": "Postcode",
                "shipping_address.city": "Plaats",
                "billing_address.street_name": "Straatnaam",
                "billing_address.street_number": "Huisnummer",
                "billing_address.street_number_addition": "Toevoeging",
                "billing_address.postcode": "Postcode",
                "billing_address.city": "Plaats",
                "billing_address.country": "Land",
                "billing_address.vat_number": "BTW nummer",
                "billing_address.company_name": "Bedrijfsnaam",
                "shipping_rate_id": "Verzendmethode",
                "consultant_id": "Consultant",
                "terms_and_conditions": "Algemene voorwaarden en privacy verklaring"
            }
        },
        "routes": {
            "frequently-asked-questions": "veelgestelde-vragen",
            "blog": "blog",
            "about-us": "over-ons",
            "general-terms": "algemene-voorwaarden",
            "products": "producten",
            "privacy-policy": "privacy-beleid",
            "cookie-policy": "cookie-beleid",
            "contact": "contact",
            "search": "zoeken"
        },
        "auth": {
            "failed": "Deze combinatie van e-mailadres en wachtwoord is niet geldig.",
            "password": "Wachtwoord is onjuist.",
            "throttle": "Te veel mislukte aanmeldpogingen. Probeer het nog eens over {seconds} seconden."
        },
        "pagination": {
            "next": "Volgende &raquo;",
            "previous": "&laquo; Vorige"
        },
        "http-statuses": {
            "0": "Onbekende foutmelding",
            "100": "Doorgaan",
            "101": "Protocolwissel",
            "102": "Verwerken",
            "200": "Oké",
            "201": "Aangemaakt",
            "202": "Aanvaard",
            "203": "Niet-gemachtigde informatie",
            "204": "Geen inhoud",
            "205": "Inhoud opnieuw instellen",
            "206": "Gedeeltelijke inhoud",
            "207": "Meerdere statussen",
            "208": "Al gemeld",
            "226": "Ik ben gebruikt",
            "300": "Meerkeuze",
            "301": "Definitief verplaatst",
            "302": "Gevonden",
            "303": "Zie andere",
            "304": "Niet gewijzigd",
            "305": "Gebruik Proxy",
            "307": "Tijdelijke omleiding",
            "308": "Definitieve omleiding",
            "400": "Foute aanvraag",
            "401": "Niet geautoriseerd",
            "402": "Betaalde toegang",
            "403": "Verboden toegang",
            "404": "Niet gevonden",
            "405": "Methode niet toegestaan",
            "406": "Niet aanvaardbaar",
            "407": "Authenticatie op de proxyserver verplicht",
            "408": "Aanvraagtijd verstreken",
            "409": "Conflict",
            "410": "Verdwenen",
            "411": "Lengte vereist",
            "412": "Niet voldaan aan de vooraf gestelde voorwaarde",
            "413": "Aanvraag te groot",
            "414": "Aanvraag-URL te lang",
            "415": "Media-type niet ondersteund",
            "416": "Bereik niet bevredigend",
            "417": "Niet voldaan aan verwachting",
            "418": "Ik ben een theepot",
            "419": "Pagina verlopen",
            "421": "Verkeerd geadresseerd verzoek",
            "422": "Aanvraag kan niet worden verwerkt",
            "423": "Afgesloten",
            "424": "Gefaalde afhankelijkheid",
            "425": "Te vroeg",
            "426": "Upgrade nodig",
            "428": "Voorwaarde nodig",
            "429": "Te veel requests",
            "431": "Headers van de aanvraag te lang",
            "444": "Verbinding gesloten zonder reactie",
            "449": "Opnieuw proberen met",
            "451": "Toegang geweigerd om juridische redenen",
            "499": "Klant Gesloten Verzoek",
            "500": "Interne serverfout",
            "501": "Niet geïmplementeerd",
            "502": "Slechte poort",
            "503": "Dienst niet beschikbaar",
            "504": "Gateway-time-out",
            "505": "HTTP-versie wordt niet ondersteund",
            "506": "Variant onderhandelt ook",
            "507": "Onvoldoende opslag",
            "508": "Loop gedetecteerd",
            "509": "Bandbreedte overschreden",
            "510": "Niet verlengd",
            "511": "Netwerkauthenticatie vereist",
            "520": "Onbekende foutmelding",
            "521": "Webserver is onbereikbaar",
            "522": "Connectie duurt te lang",
            "523": "Herkomst is onbereikbaar",
            "524": "Time-out opgetreden",
            "525": "SSL-handshake mislukt",
            "526": "Ongeldig SSL-certificaat",
            "527": "Railgun foutmelding",
            "598": "Time-outfout netwerk lezen",
            "599": "Fout bij time-out netwerkverbinding",
            "unknownError": "Onbekende foutmelding"
        },
        "passwords": {
            "reset": "Het wachtwoord van uw account is gewijzigd.",
            "sent": "We hebben een e-mail verstuurd met instructies om een nieuw wachtwoord in te stellen.",
            "throttled": "Gelieve even te wachten en het dan opnieuw te proberen.",
            "token": "Deze wachtwoordherstellink is niet (meer) geldig.",
            "user": "We hebben een e-mail verstuurd met instructies om een nieuw wachtwoord in te stellen."
        }
    }
}
